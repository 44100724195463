
//import Route
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { authRoutes, publicRoutes } from 'Routes/routes';
import { NonAuthLayout } from 'Components/Layouts/NonAuthLayout';
import { RouteWrapper } from 'Routes/RouteWrapper';
import { RouteWithAuthWrapper } from 'Routes/RouteWithAuthWrapper';
import { Error404 } from 'pages/error/Error404';
import { PageLazy } from 'pages/PageLazy';
import { useMenu } from 'Components/Contexts/MenuContext';
import { Loader } from 'UI/Common/Loader';


function Routes() {

  const {isInitialized, menu} = useMenu();

  const router = createBrowserRouter(createRoutesFromElements(
    <>
        {
          publicRoutes.map((route, index) => {
            if(menu.includes(route.name)) {
                return (
                    <Route 
                        key={index}
                        path={route.path}
                        element={
                        <NonAuthLayout>
                            <RouteWrapper title={route?.title} hasLayout={false}>
                                <PageLazy route={route} />
                            </RouteWrapper>
                        </NonAuthLayout>
                        }
                    />
                )
            }
            return '';
          })
        }
        {
          authRoutes.map((route, index) => {
            if(menu.includes(route.name)) {
                return (
                    <Route
                        key={index}
                        path={route.path}
                        element={
                            <RouteWithAuthWrapper>
                                <RouteWrapper title={route?.title} hasLayout={route.withLayout ?? true}>
                                    <PageLazy route={route} />
                                </RouteWrapper>
                            </RouteWithAuthWrapper>
                        }
                    />
                )
            }
            return '';
          })
        }
        {
          isInitialized ? (
            <Route
                path="*"
                element={<Error404 />}
            />
          ): (
            <Route
                path="*"
                element={
                  <RouteWithAuthWrapper>
                      <RouteWrapper title="loading" hasLayout={true}>
                          <Loader />
                      </RouteWrapper>
                  </RouteWithAuthWrapper>
                }
            />
          )
        }
        
      </>
  ))

  return (
    <RouterProvider router={router} />
  );
}

export default Routes;
