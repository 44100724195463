import { AppConfig } from "config/AppConfig";
import { getCookie, setCookie } from "../cookieStorage";

/**
 * retrieve authentication JWT
 */
export const getAuthToken = (): string|null => {
    const token = localStorage.getItem('auth');
    if(token) {
        return JSON.parse(token);
    }

    //if remember me activated
    const cookieToken = getCookie('auth');
    if(cookieToken) {
        storeAuthToken(cookieToken);
        return cookieToken;
    }

    return null;
}

/**
 * store authentication JWT
 */
export const storeAuthToken = (
    token: string,
    rememberMe: boolean = false
): void => {
    //store in sessionStorage
    localStorage.setItem('auth', JSON.stringify(token));

    //if remember me, store in cookie
    if(rememberMe) {
        setCookie('auth', token, AppConfig.AUTH_COOKIE_EXPIRATION_TIME);
    }
}

/**
 * remove auth token (sessionStorage and cookie if exists)
 */
export const destroyAuthToken = (): void => {
    localStorage.removeItem('auth');
    setCookie('auth', '', -1);
}
