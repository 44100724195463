import { LogoDark } from "../LogoDark";
import { LogoLight } from "../LogoLight";
import { useOpenState } from "functions/customHooks/state/useOpenState";
import { CompanyChangeModal } from "Components/CompanyChange/CompanyChangeModal";
import { MouseEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { InfoBubble } from "UI/Bubble/InfoBubble";
import { useCompanyChoice } from "Components/Contexts/CompanyChoiceContext";

export const MainLogo = () => {
    const {t} = useTranslation();
    const {companyChoice} = useCompanyChoice();
    
    const [infoIsOpen, openInfo, closeInfo] = useOpenState(false);
    const [companyChangeIsOpen, openCompanyChange, closeCompanyChange] = useOpenState(false);

    const handleClick = (e: MouseEvent) => {
        e.preventDefault();
        closeInfo();
        openCompanyChange();
    }

    useEffect(() => {
        if(companyChangeIsOpen) {
            closeInfo();
        }
    }, [infoIsOpen]);

    return (
        <>
            <div style={{display: 'flex', justifyContent: 'center', cursor: 'pointer'}} onMouseOver={openInfo} onMouseLeave={closeInfo} onClick={handleClick}>
                <div className="mt-3 position-relative" >
                    <LogoDark />
                    <LogoLight />
                    {
                        infoIsOpen && (
                            <InfoBubble hasInfoIcon={false}>
                                {companyChoice.name} ({t('click_to_change')})
                            </InfoBubble>
                        )
                    }
                </div>
            </div>
            <CompanyChangeModal
                isOpen={companyChangeIsOpen}
                close={closeCompanyChange}
            />
        </>
    )
}