import { AppConfig } from "config/AppConfig"
import { CustomFetchError, customFetch } from "../customFetch/customFetch"
import { NavigateFunction } from "react-router-dom";
import { destroyAuthToken } from "functions/storage/auth/authTokenStorage";
import { storeAuthTarget } from "functions/storage/auth/authTargetStorage";
import { generateUrl } from "functions/router/urlGenerator";
import { prepareApiRequest } from "./apiRequestPreparator";
import { storeUnauthorizedEvent } from "functions/storage/auth/unauthorizedStorage";


export const apiRequest = async <T>(
    path: string,
    params: object = {},
    method: string = 'GET',
    history: NavigateFunction,
    location: {pathname: string},
    createAlert?: (color: string, message: TrustedHTML | string) => void,
    sendAuthToken: boolean = true,
    controller?: AbortController,
): Promise<T> => {
 
    const {customHeaders, preparedParams, urlParams} = prepareApiRequest(method, params, sendAuthToken);

    try {
        return await customFetch(
            AppConfig.API_BASE_URL + path,
            preparedParams,
            method,
            customHeaders,
            urlParams,
            controller,
        );
    } catch(e) {
        if(e instanceof CustomFetchError) {
            if(history) {
                if(e.status === 401) {
                    storeAuthTarget(location.pathname);
                    storeUnauthorizedEvent();
                    destroyAuthToken();
                    history(generateUrl('login'));
                } else if(e.status === 403) {
                    history(generateUrl('error_403'));
                } else if(e.status === 404) {
                    history(generateUrl('error_404'));
                }
            }
            if(createAlert && e.status === 500) {
                const message = e.data['hydra:description'] ?? null;
                const html = '<strong>Error 500</strong>' + (message ? ' : ' + message: '');
                createAlert('danger', html);
            }
        }
        throw e;
    }
}
