//MENU
export const API_MENU = "/api/menu";

//AUTH
export const API_LOGIN =  "/api/login_check";
export const API_ME = "/api/me";
export const API_RESET_PASSWORD = "/api/auth/reset_password";
export const API_CHANGE_PASSWORD = "/api/auth/change_password";
export const API_FORGOTTEN_PASSWORD = "/api/auth/forgotten_password";

//NOTIFICATIONS
export const API_NOTIFICATIONS = "/api/notifications";

//ENTITY_ATTACHMENTS
export const API_ENTITY_ATTACHMENT = '/api/entity_attachment';
export const API_ENTITY_ATTACHMENTS = '/api/entity_attachments';
export const API_ENTITY_ATTACHMENT_DOWNLOAD = '/api/entity_attachment/download';

//ENTITIES
export const API_USERS = "/api/users";

export const API_COUNTRIES = "/api/countries";
export const API_ADDRESSES = "/api/addresses";

export const API_COMPANIES = "/api/companies";
export const API_COMPANY_LOCATIONS = "/api/company_locations";
export const API_BANK_ACCOUNTS = "/api/bank_accounts";

export const API_ITEMS = '/api/items';
export const API_ITEM_COLLECTIONS = "/api/items_collections";
export const API_ITEM_FAMILIES = "/api/items_families";

export const API_PRICE_LISTS = "/api/price_lists";

export const API_THIRD_PART_CATEGORIES = '/api/third_part_categories';
export const API_CUSTOMERS = '/api/customers';

export const API_CONTACTS = "/api/contacts";
export const API_PAYMENT_TERMS = "/api/payment_terms";
export const API_VAT_RATES = "/api/vat_rates";
export const API_SHIPPING_COST_RANGES = "/api/shipping_cost_ranges";

export const API_PROPOSALS = "/api/proposals";
export const API_PROPOSALS_VALIDATE = "/api/proposals/validate";
export const API_PROPOSALS_DEVALIDATE = "/api/proposals/devalidate";
export const API_PROPOSALS_ACCEPT = "/api/proposals/create_orders";
export const API_PROPOSAL_PRICING = "/api/proposals/pricing";
export const API_PROPOSAL_ITEMS = "/api/proposal_items";

export const API_ORDERS = "/api/orders";
export const API_ORDERS_VALIDATE = "/api/orders/validate";
export const API_ORDER_PRICING = "/api/orders/pricing";
export const API_ORDER_ITEMS = "/api/order_items";

export const API_DELIVERY_NOTES = "/api/delivery_notes";
export const API_DELIVERY_NOTES_VALIDATE = "/api/delivery_notes/validate";
export const API_DELIVERY_NOTE_PRICING = "/api/delivery_notes/pricing";
export const API_DELIVERY_NOTE_ITEMS = "/api/delivery_note_items";

export const API_COMPLAINT_DELIVERY_NOTE_ITEMS = "/api/complaint/get_shipped_delivery_note_from_order";
export const API_COMPLAINT_ORDER_CREATE = "/api/complaint/create_from_order"
