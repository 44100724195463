import { AppConfig } from "config/AppConfig";
import { setHtmlTitleCompany } from "functions/dom/setHtmlTitle";
import { getStoredCompanyChoice, storeCompanyChoice } from "functions/storage/auth/companyChoiceStorage";
import { PropsWithChildren, createContext, useContext, useEffect, useState } from "react"
import { CompanyChoiceType, CompanyType } from "type/mainTypes";
import { getEntityAttachmentUrl } from "functions/files/entityAttachmentUrlResolver";

export const defaultCompanyChoice: CompanyChoiceType = {
    id: 10000,
    code: '10000',
    name: AppConfig.DEFAULT_COMPANY_NAME,
    logo: null,
    smallLogo: null
};

type CompanyChoiceContextType = {
    companyChoice: CompanyChoiceType,
    setCompanyChoice: (company: CompanyType) => void
}

const CompanyChoiceContext = createContext<CompanyChoiceContextType|null>(null);

export const CompanyChoiceProvider = ({children}: PropsWithChildren) => {
    const [companyChoice, setCompanyChoice] = useState<CompanyChoiceType>(getStoredCompanyChoice() || defaultCompanyChoice);

    const handleSetCompanyChoice = (company: CompanyType) => {
        const newCompanyChoice = {
            id: company.id,
            code: company.code,
            name: company.name,
            logo: company?.logo?.id ? getEntityAttachmentUrl(company.logo.id) : null,
            smallLogo: company?.smallLogo?.id ? getEntityAttachmentUrl(company.smallLogo.id): null,
        };
        setCompanyChoice(newCompanyChoice);
        storeCompanyChoice(newCompanyChoice);
    };

    useEffect(() => {
        setHtmlTitleCompany(companyChoice.name);
    }, [companyChoice]);

    return (
        <CompanyChoiceContext.Provider value={{
            companyChoice,
            setCompanyChoice: handleSetCompanyChoice
        }}>
            {children}
        </CompanyChoiceContext.Provider>
    )
}

export const useCompanyChoice = (): CompanyChoiceContextType => {
    const companyChoiceContext = useContext(CompanyChoiceContext);
    if(!companyChoiceContext) {
        throw new Error('To use CompanyChoice Context, your component must be wrapped by <CompanyChoiceProvider>');
    }
    return companyChoiceContext;
}
