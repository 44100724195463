import React from 'react';

//import Scss
import './assets/scss/themes.scss';

import Routes from 'Routes';


function App() {

  return (
    <Routes />
  );
}

export default App;



