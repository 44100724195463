import { useTranslation } from "react-i18next"

import { useCompanyChoice } from "Components/Contexts/CompanyChoiceContext";
import { CompanyChoiceType } from "type/mainTypes";
import { DefaultLightLargeLogo, DefaultLightSmallLogo } from "./DefaultLogo";

export const LogoLight = () => {
    
    const {companyChoice} = useCompanyChoice();

    return (
        <div className="logo logo-light">
            <span className="logo-sm">
                <LogoLightSmall companyChoice={companyChoice} />
            </span>
            <span className="logo-lg">
                <LogoLightLarge companyChoice={companyChoice} />
            </span>
        </div>
    )
}

type LogoSizeProps = {
    companyChoice: CompanyChoiceType
};

const LogoLightSmall = ({
    companyChoice
}: LogoSizeProps) => {
    const {t} = useTranslation();

    let logo = companyChoice.smallLogo;

    if(!logo) {
        logo = companyChoice.logo;
    }

    if(!logo) {
        return <DefaultLightSmallLogo />
    }

    return (
        <img src={logo} alt={t('logo')} width="45" />
    )
};

const LogoLightLarge = ({
    companyChoice
}: LogoSizeProps) => {
    const {t} = useTranslation();

    if(!companyChoice.logo) {
        return <DefaultLightLargeLogo companyChoice={companyChoice} />
    }

    return (
        <img src={companyChoice.logo} alt={t('logo')} width="100" />
    )
};
