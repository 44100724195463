import { CompanyChoiceType } from "type/mainTypes";

const companyChoiceKey = 'company_choice'

export const storeCompanyChoice = (companyChoice: CompanyChoiceType): void => {
    localStorage.setItem(
        companyChoiceKey,
        JSON.stringify(companyChoice)
    );
}

export const getStoredCompanyChoice = (): CompanyChoiceType|null => {
    const companyChoice = localStorage.getItem(companyChoiceKey);

    if(companyChoice) {
        return JSON.parse(companyChoice);
    }
    return null;
}

export const destroyStoredCompanyChoice = () => {
    localStorage.removeItem(companyChoiceKey);
}
