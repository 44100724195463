import { useTranslation } from "react-i18next"

import defaultLogo from "../../../assets/images/logo-sm.png";

import { useCompanyChoice } from "Components/Contexts/CompanyChoiceContext";
import { CompanyChoiceType } from "type/mainTypes";
import { DefaultDarkLargeLogo, DefaultDarkSmallLogo } from "./DefaultLogo";


export const LogoDark = () => {

    const {companyChoice} = useCompanyChoice();

    return (
        <div className="logo logo-dark" style={{lineHeight: '1.5'}}>
            <span className="logo-sm">
                <LogoDarkSmall companyChoice={companyChoice} />
            </span>
            <span className="logo-lg">
                <LogoDarkLarge companyChoice={companyChoice} />
            </span>
        </div>
    )
}

type LogoSizeProps = {
    companyChoice: CompanyChoiceType
};

const LogoDarkSmall = ({
    companyChoice
}: LogoSizeProps) => {
    const {t} = useTranslation();

    let logo = companyChoice.smallLogo;

    if(!logo) {
        logo = companyChoice.logo;
    }

    if(!logo) {
        return <DefaultDarkSmallLogo />
    }

    return (
        <img src={logo} alt={t('logo')} width="45" />
    )
};

const LogoDarkLarge = ({
    companyChoice
}: LogoSizeProps) => {
    const {t} = useTranslation();

    if(!companyChoice.logo) {
        return <DefaultDarkLargeLogo companyChoice={companyChoice} />
    }

    return (
        <img src={companyChoice.logo} alt={t('logo')} width="100" />
    )
};
