import { API_LOGIN } from "Routes/apiRoutes";
import { AppConfig } from "config/AppConfig";
import { customFetch } from "functions/api/customFetch/customFetch";
import { generateUrl } from "functions/router/urlGenerator";
import { destroyAuthTarget, getAuthTarget } from "functions/storage/auth/authTargetStorage";
import { storeAuthToken } from "functions/storage/auth/authTokenStorage";
import { NavigateFunction } from "react-router-dom";
import { LoginData } from "type/formTypes";


export const loginUser = async (
    loginData : LoginData, 
    history : NavigateFunction, 
    ) => {

    try {
        const data = await customFetch(AppConfig.API_BASE_URL + API_LOGIN, {
            username: loginData.username,
            password: loginData.password
        }, 'POST');

        //store authentication JWT
        storeAuthToken(data.token);

        //redirection
        const target = getAuthTarget();
        if(target) {
            //remove target
            destroyAuthTarget();
            //redirect to target
            history(target);
        } else {
            history(generateUrl('home'));
        }
    } catch(e: any) {
        throw e;
    }
};