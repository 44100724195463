import { API_COMPANIES } from "Routes/apiRoutes";
import { AppConfig } from "config/AppConfig";
import { prepareApiRequest } from "functions/api/apiRequest/apiRequestPreparator";
import { convertPaginatedResponse } from "functions/api/convertor/apiPlatformConversion/paginatedResponseConvertor";
import { customFetch } from "functions/api/customFetch/customFetch";
import { ApiPlatformPaginatedType } from "type/searchTypes";

export const loadCompanies = async (): Promise<any[]> => {
    const {customHeaders, urlParams} = prepareApiRequest('GET');
    try
    {
        const response: ApiPlatformPaginatedType = await customFetch(AppConfig.API_BASE_URL + API_COMPANIES, {itemsPerPage: 1000}, 'GET', customHeaders, urlParams);
        return convertPaginatedResponse(response).items;
    }
    catch(e) {
        throw new Error('Server error fetching companies');
    }
};
