import { CompanyChoiceType } from "type/mainTypes"

type DefaultLogoProps = {
    companyChoice: CompanyChoiceType
};

export const DefaultDarkLargeLogo = ({
    companyChoice
}: DefaultLogoProps) => {
    return (
        <div className="bg-secondary text-light" style={{height: '85px', width: '85px', padding: '10px 15px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <span style={{fontWeight: '600'}}>{companyChoice.name}</span>
        </div>
    )
}

export const DefaultDarkSmallLogo = () => {
    return (
        <div className="bg-secondary" style={{height: '45px', width: '45px'}}>
        </div>
    )
}

export const DefaultLightLargeLogo = ({
    companyChoice
}: DefaultLogoProps) => {
    return (
        <div className="bg-light text-dark" style={{height: '85px', width: '85px', padding: '10px 15px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <span style={{fontWeight: '600', color: '#FFF'}}>{companyChoice.name}</span>
        </div>
    )
}

export const DefaultLightSmallLogo = () => {
    return (
        <div className="bg-light" style={{height: '45px', width: '45px'}}>
        </div>
    )
}
